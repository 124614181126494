import React, { useState, useEffect } from 'react';
import LogoLoader from '../../components/LogoLoader';
import { Button, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import shadows from '@mui/material/styles/shadows';
import Box from '@mui/system/Box';
import { useTranslation } from 'react-i18next';
import StepperPopup from '../../components/StepperPopup';
import MultipleStatusEdit from '../../components/multipleEditDialog/MultipleStatusEdit';
import MultipleParkingLotEdit from '../../components/multipleEditDialog/MultipleParkingLotEdit';
import { statusMappingBackendToFrontend } from '../../components/DataTable';
import Feedback from '../../components/DataTable/Feedback';
import { mapStatus } from '../../pages/Stations';
import ConfirmationBox from './components/ConfirmationBox';
import { useTokenUtils } from '../../services/utils/tokenUtils';

export default function EditChargingStationInformation({
  chargingStation,
  setRefreshTheData,
  onCloseFeedback,
}) {
  const { isAdminRole } = useTokenUtils();
  const { t, i18n } = useTranslation();
  const { status } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isStatusEditModalOpen, setIsStatusEditModalOpen] = useState(false);
  const [payload, setPayload] = useState([]);
  const [checkStatusChange, setCheckStatusChange] = useState(false);
  const [showFeedbackStatus, setShowFeedbackStatus] = useState(false);
  const [errorTitle, setErrorTitle] = useState(0);
  const [error, setError] = useState('');
  const [submit, setSubmit] = useState(0);
  const [showErrorEmptyParkingEdit, setShowErrorEmptyParkingEdit] =
    useState(false);
  const [showFeedbackParking, setShowFeedbackParking] = useState(false);
  const [showFeedbackReset, setShowFeedbackReset] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDuplicateModel, setShowDuplicateModel] = useState(false);
  const [showDuplicateModelData, setShowDuplicateModelData] = useState('');
  const [showDuplicateModelTitle, setShowDuplicateModelTitle] = useState('');
  const [isRestartChargingDisabled, setIsRestartChargingDisabled] =
    useState(false);
  const open = Boolean(anchorEl);
  useEffect(() => {
    chargingStation?.connectorStatus === 'OFFLINE' ||
    chargingStation?.connectorStatus === 'INACTIVE'
      ? setIsRestartChargingDisabled(true)
      : setIsRestartChargingDisabled(false);
  }, [chargingStation?.connectorStatus]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChargingStationEdit = () => {
    const parkingLot = chargingStation?.address?.parkingLot;
    if (
      chargingStation?.connectorStatus === 'IDLE' ||
      (chargingStation?.connectorStatus === 'CONNECTED_UNAVAILABLE' &&
        !!parkingLot)
    ) {
      setIsStatusEditModalOpen(true);
    } else if (
      !(
        chargingStation?.connectorStatus === 'IDLE' ||
        (chargingStation?.connectorStatus === 'CONNECTED_UNAVAILABLE' &&
          !!parkingLot) ||
        chargingStation?.connectorStatus === 'OCCUPIED_UNCHARGED' ||
        chargingStation?.connectorStatus === 'OCCUPIED_CHARGING'
      )
    ) {
      setErrorTitle(t('notpossible'));
      setError(
        <>
          {t('csstatus')} <span style={{ color: status.standby }}>Standby</span>{' '}
          {i18n.language === 'en' ? 'or ' : 'oder '}
          <span style={{ color: status.aktiv }}>{t('LBLActive')}</span>{' '}
          {t('tobeedited')}
        </>
      );
    } else if (
      chargingStation?.connectorStatus === 'OCCUPIED_UNCHARGED' ||
      chargingStation?.connectorStatus === 'OCCUPIED_CHARGING'
    ) {
      setErrorTitle('Ladestation im Betrieb');
      setError(
        <>
          {t('LBLChastationInuse')}: <br />{' '}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <ul>
              <li key={chargingStation?.cpId}>{chargingStation?.cpId}</li>
            </ul>
          </Box>
          {t('csnotcahn')}
        </>
      );
    }
  };
  const handleAddParking = () => {
    if (chargingStation?.connectorStatus === 'INACTIVE') {
      setErrorTitle(t('LBLWrongSelection'));
      setError(
        <>
          {t('cswithstatus')}{' '}
          <span style={{ color: status.inaktiv }}>{t('LBLInactive')}</span>{' '}
          {t('cantedit')}.
        </>
      );
    } else setIsEditModalOpen(true);
  };
  const handleRestartChargingStation = async (response) => {
    if (response.status === 200) {
      setShowConfirmationModal(false);
      setShowFeedbackReset(true);
    } else {
      setErrorTitle(t('mistake'));
      setError(
        <>
          <Typography align="center">{t('LBLSomethingwentWrong')}</Typography>
        </>
      );
    }
  };
  const closeErrorFeedback = () => setError('');
  const cols = [
    {
      name: 'locationName',
      label: t('LBLLocation'),
    },
    {
      name: 'connectorId',
      label: 'Connector ID',
    },
    {
      name: 'parkingLot',
      label: t('LBLParkingLotNum'),
    },
    {
      name: 'connectorStatus',
      label: t('LBLCHarginStationStatus'),
      type: 'select',
      selectConfig: {
        label: t('LBLCHarginStationStatus'),
        values: [
          {
            value: 'IDLE',
            label: statusMappingBackendToFrontend['idle'],
          },
          {
            value: 'CONNECTED_UNAVAILABLE',
            label: mapStatus('connected_unavailable', ' '),
          },
        ],
      },
    },
  ];
  const handleChangeError = (input) => {
    setShowDuplicateModelData(input.detail);
    setShowDuplicateModelTitle(input.title);
    setShowDuplicateModel(true);
  };
  const closeDuplicateError = () => {
    setShowDuplicateModel(false);
    setSubmit(0);
    onCloseFeedback();
  };
  return (
    <>
      <LogoLoader loading={false} />
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
        sx={{
          position: 'absolute',
          boxShadow: shadows[3],
          top: ({ spacing }) => spacing(10),
          right: ({ spacing }) => spacing(10),
          zIndex: ({ zIndex }) => zIndex.appBar,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {t('factions')}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'edit-selected',
        }}
        sx={{
          textAlign: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            handleAddParking();
            handleClose();
          }}
        >
          <Typography align="center" sx={{ width: '100%' }}>
            {t('LBLParkplatzhinterlegen')}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleChargingStationEdit();
            handleClose();
          }}
        >
          <Typography align="center" sx={{ width: '100%' }}>
            {t('ChangechargingStatus')}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowConfirmationModal(true);
            handleClose();
          }}
          disabled={isRestartChargingDisabled}
        >
          <Typography align="center" sx={{ width: '100%' }}>
            {t('BTNChangeConfiguration')}
          </Typography>
        </MenuItem>
      </Menu>
      <StepperPopup
        isOpen={Boolean(isEditModalOpen)}
        setIsOpen={setIsEditModalOpen}
        title={t('LBLParkplatzhinterlegen')}
        steps={[
          {
            label: t('EnterINfo'),
            component: (
              <MultipleParkingLotEdit
                data={[
                  {
                    ...chargingStation,
                    parkingLot: chargingStation?.address?.parkingLot,
                  },
                ]}
                payload={payload}
                setPayload={setPayload}
                isReadOnly={false}
                showEmptyFieldError={showErrorEmptyParkingEdit}
                setShowFeedbackError={handleChangeError}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setShowErrorEmptyParkingEdit(true);
              },
              canNext:
                payload.filter(
                  (row) =>
                    !row.parkingLotNumber || row.parkingLotNumber?.length > 5
                ).length <= 0,
            },
          },
          {
            label: t('confirmInfo'),
            component: (
              <MultipleParkingLotEdit
                data={[
                  {
                    ...chargingStation,
                    parkingLot: chargingStation?.address?.parkingLot,
                  },
                ]}
                payload={payload}
                setPayload={setPayload}
                isReadOnly={true}
                submit={submit}
                setShowFeedback={setShowFeedbackParking}
                setShowFeedbackError={handleChangeError}
              />
            ),
            nextAction: {
              label: t('LBLSave'),
              icon: <></>,
              action: () => {
                setSubmit((prevState) => prevState + 1);
                setIsEditModalOpen(false);
                setShowErrorEmptyParkingEdit(false);
              },
              canNext: false,
            },
          },
        ]}
      />
      <StepperPopup
        isOpen={Boolean(isStatusEditModalOpen)}
        setIsOpen={setIsStatusEditModalOpen}
        title={t('ChangechargingStatus')}
        steps={[
          {
            label: t('LBLChangeEnter'),
            component: (
              <MultipleStatusEdit
                data={[
                  {
                    ...chargingStation,
                    parkingLot: chargingStation?.address?.parkingLot,
                  },
                ]}
                cols={cols}
                payload={payload}
                setPayload={setPayload}
                isReadOnly={false}
                checkStatusChange={checkStatusChange}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setCheckStatusChange(true);
              },
              canNext:
                !payload.filter((value) => value?.changed === false).length > 0,
            },
          },
          {
            label: t('LBLConfirmChanges'),
            component: (
              <MultipleStatusEdit
                data={[chargingStation]}
                cols={cols}
                payload={payload}
                setPayload={setPayload}
                submit={submit}
                isReadOnly={true}
                setShowFeedback={setShowFeedbackStatus}
              />
            ),
            nextAction: {
              label: t('LBLSave'),
              icon: <></>,
              action: () => {
                setSubmit((prev) => prev + 1);
                setIsStatusEditModalOpen(false);
              },
              canNext: false,
            },
          },
        ]}
      />
      <Feedback
        open={showFeedbackParking}
        handleClose={() => {
          setShowFeedbackParking(false);
          setSubmit(0);
          onCloseFeedback();
        }}
        message={t('ParkinglotUpdated')}
        title={t('lotNumberUpdated')}
      />
      <Feedback
        open={showFeedbackStatus}
        handleClose={() => {
          setRefreshTheData((prevState) => prevState + 1);
          setShowFeedbackStatus(false);
          setSubmit(0);
        }}
        message={t('ParaCHarginst')}
        title={t('statussubmitted')}
      />
      <Feedback
        open={showFeedbackReset}
        handleClose={() => {
          setShowFeedbackReset(false);
          setSubmit(0);
        }}
        message={t('successResetMsg')}
        title={t('successResetTitle')}
      />
      <Feedback
        open={!!error}
        title={errorTitle}
        message={
          <Typography variant="subtitle1" sx={{ width: 400, mx: 10 }}>
            {error}
          </Typography>
        }
        severity="warning"
        handleClose={closeErrorFeedback}
      />
      <Feedback
        open={showDuplicateModel}
        title={showDuplicateModelTitle}
        message={
          <Typography variant="subtitle1" sx={{ width: 400, mx: 10 }}>
            {showDuplicateModelData}
          </Typography>
        }
        severity="warning"
        handleClose={closeDuplicateError}
      />
      <ConfirmationBox
        open={showConfirmationModal}
        chargingStation={chargingStation}
        title={t('ConfirmationTitle')}
        message={t('ConfirmationMessage')}
        handleSubmit={(response) => handleRestartChargingStation(response)}
        handleClose={() => setShowConfirmationModal(false)}
      />
    </>
  );
}